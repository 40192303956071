<template>
<table v-if="treeData">
    <tr v-if="treeData.id!=0">
        <td :colspan="treeData.son ? treeData.son.length * 2 : 1" :class="{parentLevel: treeData.son, extend: treeData.son && treeData.son.length && treeData.extend}">
            <div :class="{node: true}">
                <div class="person" :class="{person2:treeData.id==0}" @click="$emit('click-node', treeData)">
                    <div class="box" :class="{parent: !treeData.son}" @click="jumpPageSelf(treeData)">
                        <p class="p1">{{treeData.ext_department_name}} > {{treeData.realname}}</p>
                    </div>
                </div>
            </div>
            <div class="extend_handle" v-if="treeData.son && treeData.son.length" @click="toggleExtend(treeData)"></div>
        </td>
    </tr>
    <!-- 这是一个递归组件,注意,这里还要调用,需要传递的数据这里也要传递,否则操作时拿不到子级的数据 -->
    <tr v-if="treeData.son && treeData.son.length && treeData.extend">
        <td v-for="(son, index) in treeData.son" :key="index" colspan="2" class="childLevel">
            <TreeChart :json="son" :isDetail="isDetail" @click-node="$emit('click-node', $event)" />
        </td>
    </tr>
</table>
</template>

<script>
export default {
    name: "TreeChart",
    props: {
        json: {}, // 渲染数据
        isDetail: {
            default: true // 是否是详情
        },
        type: {},
        inputweek: {},
        inputmonth: {},
        range: {}
    },
    data() {
        return {
            treeData: {},
        };
    },
    watch: {
        isDetail: function(val) { // 是否是详情,详情不能添加编辑
            this.isDetail = val;
        },
        json: {
            // 遍历当前的数据
            handler: function(Props) {
                let extendKey = function(jsonData) {
                    jsonData.extend = jsonData.extend === void 0 ? true : !!jsonData.extend;
                    return jsonData;
                };
                if (Props) {
                    this.treeData = extendKey(Props);
                }
            },
            immediate: true,
            deep: true
        },
        range(val) {
            this.rangeSelf = val
        }
    },
    methods: {
        toggleExtend(treeData) {
            treeData.extend = !treeData.extend;
            this.$forceUpdate();
        },

        jumpPageSelf(param) {
            this.$router.push({
                path: '/admin2',
                query: {
                    id: param.id,
					realname:param.realname
                }
            })
        }
    }
};
</script>

<style lang="less" scoped>
table {
    border-collapse: separate!important;
    border-spacing: 0!important;
    // background: #F6F6F6;
    margin: 0 auto;
}
td {
    position: relative;
    vertical-align: top;
    padding: 0 0 50px;
    text-align: center;
}

.parent {
    .p1 {
        background: #EEEEEE!important;
        color: #333333!important;
    }
    // background: #199ed8 !important;
    // font-weight: bold;
}
.extend_handle {
    position: absolute;
    left: 50%;
    bottom: 27px;
    width: 10px;
    height: 10px;
    padding: 10px;
    transform: translate3d(-15px,0,0);
    cursor: pointer;
}
.extend_handle:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 2px solid;
    border-color: #ccc #ccc transparent transparent;
    transform: rotateZ(135deg);
    transform-origin: 50% 50% 0;
    transition: transform ease 300ms;
}
.extend_handle:hover:before {
    border-color: #333 #333 transparent transparent;
}
.extend .extend_handle:before {
    transform: rotateZ(-45deg);
}

.extend::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 15px;
    height: 15px;
    border-left: 2px solid #ccc;
    transform: translate3d(-1px,0,0);
}
.childLevel::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 100%;
    height: 15px;
    border-left: 2px solid #ccc;
    transform: translate3d(-1px,0,0);
}
.childLevel::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: -15px;
    border-top: 2px solid #ccc;
}
.childLevel:first-child:before,
.childLevel:last-child:before {
    display: none;
}
.childLevel:first-child:after {
    left: 50%;
    height: 15px;
    border: 2px solid;
    border-color: #ccc transparent transparent #ccc;
    border-radius: 6px 0 0 0;
    transform: translate3d(1px,0,0);
}
.childLevel:last-child:after {
    right: 50%;
    height: 15px;
    border: 2px solid;
    border-color: #ccc #ccc transparent transparent;
    border-radius: 0 6px 0 0;
    transform: translate3d(-1px,0,0);
}
.childLevel:first-child.childLevel:last-child::after {
    left: auto;
    border-radius: 0;
    border-color: transparent #ccc transparent transparent;
    transform: translate3d(1px,0,0);
}

.node {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    text-align: left;
    padding: 0 5px;
}
.node .person {
    padding-top: 15px;
    position: relative;
    display: inline-block;
    z-index: 2;
    width: 200px;
    overflow: hidden;

}

.node .person2 {
    width: 220px;
    .company {
        > .name {
            color: #333;
            width: 100%;
            height: 70px;
            line-height: 1.5;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            text-align: center;
            background: #FFFFFF;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 4px;
                height: 100%;
                background: #008685;
            }
        }

    }
}
.node .person .avat {
    padding: 10px 5px 5px;
    display: block;
    width: 100%;
    height: 100%;
    margin: auto;
    word-break: break-all;
    background: #ffcc00;
    box-sizing: border-box;
    border-radius: 4px;
    .opreate_icon {
        display: none;
    }
    &:hover {
        .opreate_icon {
            display: block;
            position: absolute;
            top: -3px;
            right: -3px;
            padding: 5px;
        }
    }

    &.company {
        background: #199ed8;
    }
    &.other {
        background: #ccc;
    }
}
.node .person .avat img {
    cursor: pointer;
}
.node .person .name {
    height: 2em;
    line-height: 2em;
    overflow: hidden;
    width: 100%;
}
.node.hasMate::after {
    content: "";
    position: absolute;
    left: 2em;
    right: 2em;
    top: 15px;
    border-top: 2px solid #ccc;
    z-index: 1;
}
.node.hasMate .person:last-child {
    margin-left: 1em;
}

.el-dialog__header {
    padding: 30px 0 0;
    margin: 0 30px;
    border-bottom: 1px solid #F1F1F1;
    text-align: left;
    .el-dialog__title {
        font-size: 14px;
        font-weight: bold;
        color: #464C5B;
        line-height: 20px;
    }
}
.tips {
    padding: 0 20px;
    .el-select {
        width: 100%;
    }
    .blue {
        color: #00B5EF;
    }
    .check {
        margin-left: 100px;
    }
    .inquiry {
        font-weight: bold;
    }
    .el-form-item__label {
        display: block;
        float: none;
        text-align: left;
    }
    .el-form-item__content {
        margin-left: 0;
    }
}
.el-dialog__body {
    padding: 30px 25px;
    p {
        margin-bottom: 15px;
    }
}
.el-dialog__headerbtn {
    top: 30px;
    right: 30px;
}

// 竖向
.landscape {
    transform: translate(-100%,0) rotate(-90deg);
    transform-origin: 100% 0;
    .node {
        text-align: left;
        // height: 8em;
        // width: 8em;
        height: 230px;
        width: 230px;
    }
    .person {
        position: relative;
        transform: rotate(90deg);
        // padding-left: 4.5em;
        // height: 4em;
        top: 25px;
        left: 12px;
        width: 200px;
        .company {
            > .name {
                color: #333;
                width: 100%;
                height: 70px;
                line-height: 1.5;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                text-align: center;
                background: #FFFFFF;
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 4px;
                    height: 100%;
                    background: #008685;
                }
            }

        }
    }

    .person2 {
        top: 50px;
        left: -5px;
        width: 250px;
    }
}

.el-popover {
    .el-button {
        padding: 8px !important;
        margin-left: 5px !important;
        float: left;
    }
}

.box {
    width: 190px;
    background: #fff;
    font-size: 14px;
    color: #333;
    box-shadow: 0 0 3px #ddd;
    margin: 5px auto;
    cursor: pointer;
    .p1 {
        height: 60px;
        line-height: 60px;
        background: #008685;
        font-size: 16px;
        color: #fff;
        padding: 0 15px;
    }
    .p2 {
        border-bottom: 1px dashed #999999;
        margin: 0 15px 15px;
        padding: 15px 0;
    }
    .p3 {
        padding: 0 15px 15px;
    }
    .p4 {
        padding: 0 15px 15px;
    }
    span {
        float: right;
    }
    .color {
        color: #008685;
    }
}
</style>
