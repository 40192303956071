<template lang="">
<div class="overview">
    <section class="">
        <TreeChart v-if="isShow" :json="treeData" :class="{landscape: isVertical}" />
    </section>
</div>
</template>

<script>
import TreeChart from '@/components/treeData2'
export default {
    components: {
        TreeChart
    },
    data() {
        return {
            isShow: false,
            treeData: {
                // ext_department_name: this.$root.userInfo.ext_company_name,
                // id: 0,
            },
            isVertical: false, // 是否是竖方向,只给最外层的添加
            isDetail: true, // 是否是详情,不可编辑操作
        }
    },
    mounted() {
		this.getData()
    },
    methods: {
        getData() {
            this.http.post('/admin.user/indexWorkOutLook').then(re => {
				this.treeData={
					ext_department_name: this.$root.userInfo.ext_company_name,
	                id: 0,
					son:re.data
				}
				this.range=re.time;
                this.isShow = true
            })
        },
    },
}
</script>
